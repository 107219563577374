@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@font-face {
  font-family: 'cuyabra';
  src: url(/static/media/cuyabra-Bold.e7ac7d70.eot);
  src: url(/static/media/cuyabra-Bold.e7ac7d70.eot?#iefix) format('embedded-opentype'),
      url(/static/media/cuyabra-Bold.ac07efcb.woff2) format('woff2'),
      url(/static/media/cuyabra-Bold.b87c5f14.woff) format('woff'),
      url(/static/media/cuyabra-Bold.7144d659.ttf) format('truetype'),
      url(/static/media/cuyabra-Bold.238a7359.svg#cuyabra-Bold) format('svg');
  font-weight: bold;
  font-style: normal;
}



body {
  background-color: #fff !important;
  font-family: Montserrat;
}

header {font-size: 40px; font-weight: 500; background-color: #f0f0f0; padding:25px 0; margin-bottom: 60px;}

select {background-color: #f0f0f0; border:none; padding:0 30px; height:53px; color:rgba(0, 0, 0, 0.5); width: 100%;}

input[type='text'] {background-color: #f0f0f0; border:none; padding:30px; height:53px; color:rgba(0, 0, 0, 0.5); width: 100%;}

textarea {background-color: #f0f0f0; border:none; padding:30px; color:rgba(0, 0, 0, 0.5); width: 100%;}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #f0f0f0;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #c6c6c6;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #c6c6c6;
  cursor: pointer;
}

button {
  -webkit-appearance: none;
  border:3px solid #08212b;
  background-color: transparent;
  border-radius:50px !important;
  color:#08212b;
  font-size: 16px!important;
  padding:0 30px;
  font-weight: 500;
  height:60px;
}


.preview-wrapper {
  text-align: center;
}
.preview {
  
  position: relative;
  -webkit-transition: 1s;
  transition: 1s;
  display: inline-block;
}

.preview .bg {
  background-color: #271d41;
  left: 0;
  bottom: 0;
  width: calc(100% - 45px);
  height: calc(100% - 45px);
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  /* box-shadow: inset 400px -300px 300px 0px #271d41; */
  border-radius: 0 80px 0px 0;
}

.preview .logo {
  position: absolute;
  top: 110px;
  right: 110px;
  max-width:300px
}

.preview .icon {
  position: absolute;
  bottom: 70px;
  width: 132px; 
  /* background-color: #f39520; */
  /* border-radius: 0 20px 20px 0; */
   left: 70px;
  height: 132px; 
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-transition: 1s;
  transition: 1s;
}

/* .preview .icon.projectmanagement {
  background-color: #1f1635;
}
.preview .icon.advies {
  background-color: #f39420;
}
.preview .icon.interim-management {
  background-color: #25b4b1;
}
.preview .icon.professionalisering {
  background-color: #e7313c;
} */

.preview .icon.trainingprogramma img:nth-child(1) {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
.preview .icon.interim-planner img:nth-child(2) {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
.preview .icon.ondersteuning img:nth-child(3) {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}
/* .preview .icon.professionalisering img:nth-child(4) {
  transform: translateX(0);
  opacity: 1;
} */

.preview .icon img {
  /* right: 30px; */
  display: inline-block;
  position: absolute;
  -webkit-transform: translateX(-200%);
          transform: translateX(-200%);
  -webkit-transition: 1s;
  transition: 1s;
  opacity: 0;
  /* height: 60%; */
}
.preview .text {
  color: #fff;
  font-size: 50px;
  font-family: "cuyabra";
  font-weight: 700;
  width: 100%;
  line-height: 1.2em;
  position: absolute;
  bottom: 70px;
  left: 0;
  padding-left:250px;
  text-align: left;
  white-space: pre;
  overflow: hidden;
  height:132px;
  padding-right: 75px;
  display: flex;
  align-items: center;
  text-shadow: 2px 2px 10px rgb(0 0 0 / 75%);
}

.preview .text > div{}

.inputs-wrapper {
  padding: 50px 0;
  margin:auto;
}


.inputs-wrapper .input {margin-bottom: 30px;}

.inputs-wrapper .input label{
  font-weight: 500;
  display: block;
}

